import { IOnboardingFields } from '../../@types/generated/contentful'
import { ContentTypes } from '../../api/contentTypeIds'
import useSearchEntries from './useSearchEntries'

// Props interface that accepts enabler
interface IProps {
  enabled?: boolean
}

export default function useGetOnboarding(props: IProps) {
  // Destrcuture props
  const { enabled = true } = props

  // Search the entries
  return useSearchEntries<IOnboardingFields>(ContentTypes.onboarding, undefined, enabled)
}
