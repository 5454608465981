/**
 * Get the onboarding groups from backend and transform them into a format that
 * can be used by the OnboardingGroupa=s component
 */

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useMemo } from 'react'
import { useUser } from '@auth0/nextjs-auth0/client'
import { IOnboardingScreenFields } from '../../../@types/generated/contentful'
import { bulkImagesQueryKey } from '../../../api/constants'
import { SimpleEntry } from '../../../api/types'
import useGetOnboarding from '../../../hooks/requests/useGetOnboarding'
import { USER_ROLES } from '../../../hooks/useRoles'
import { logError, logInfo } from '../../Common/loggers'
import { IGroupProps } from './OnboardingGroups'

export default function useGetOnboardingGroups() {
  const { data: onboardingData, error, isError, isLoading, isSuccess, isFetching } = useGetOnboarding({})
  const u = useUser()
  const country = u.user?.meta?.country

  const matchesCountryTag = (tags: any[], userCountry: string): boolean => {
    // If there are no country tags, return true
    if (tags.length === 0) {
      return true;
    }
    
    // Check if any country tag matches the user's country
    return tags.some((tag) => {
      const tagId = tag.sys.id;
      return !tagId.includes('country') || tagId === `country${userCountry}`;
    });
  };

  // Construct the groups array and ensure that the core trainer group/groups is always first
  // Also grab all the image URLs to allow for pre-loading of images
  const { groups, imageURLs }: { groups: IGroupProps[]; imageURLs: string[] } = useMemo(() => {
    const g: IGroupProps[] = []
    const imageURLs: string[] = []

    // Iterate over the onboarding groups and construct the groups array and imageURLS array
    onboardingData?.items
      ?.filter((v) => v.hasOwnProperty('fields'))
      .forEach((item) => {
        const itemCountryTags = item?.metadata?.tags ?? [];
    
        // Check if the item's country tags match the user's country
        const matchesCountry = matchesCountryTag(itemCountryTags, country);
    
        // If the item's country tags don't match the user's country, skip this item
        if (!matchesCountry) return;

        const group: IGroupProps = {
          id: item.sys.id, // The id of the onboarding group
          buttonText: item.fields.buttonText,
          groupTitle: item.fields.title,
          role: (item.fields.role && 'fields' in item.fields.role ? item.fields.role.fields?.role : '') || '',
          screens: item.fields.screen
            .filter((s): s is SimpleEntry<IOnboardingScreenFields> => 'fields' in s)
            .filter((s) => s.fields.image && 'fields' in s.fields.image && s.fields.image.fields?.file?.url)
            .map((screen) => {
              const url =
                (screen.fields.image && 'fields' in screen.fields.image ? screen.fields.image.fields?.file?.url : '') ??
                ''
              const title =
                (screen.fields.image && 'fields' in screen.fields.image ? screen.fields.image.fields?.title : '') ?? ''
              return {
                id: screen.sys.id,
                image: `https:${url}`, // Prepend the image url with https:
                imageAlt: title,
                title: screen.fields.title,
                text: screen.fields.text,
              }
            }),
        }
        g.push(group)
        group.screens.forEach((screen) => {
          // Only push the image URL if it hasn't already been pushed
          if (!imageURLs.includes(screen.image)) imageURLs.push(screen.image)
        })
      })

    const groups = g.sort(
      (a, b) => Number(a.role !== USER_ROLES.coreTrainer) - Number(b.role !== USER_ROLES.coreTrainer)
    )

    return { groups, imageURLs }
  }, [country, onboardingData?.items])

  // Use React Query and axios to create a get request for all the images
  // This will allow for pre-loading of images
  // For each image URL, create a new promise that will resolve when the image is loaded
  // Then, use Promise.all to wait for all the images to load
  useQuery({
    queryKey: bulkImagesQueryKey(imageURLs),
    queryFn: () => Promise.all(imageURLs.map((url) => axios.get(url, { responseType: 'blob' }))),
    onSuccess: () => {
      logInfo('Images loaded')
    },
    onError: () => {
      //Todo: Handle error
      logError('Error loading images')
    },
  })

  return { data: groups, error, isError, isLoading, isSuccess, isFetching }
}
