import { ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import { useCallback } from 'react'
import useMst from '../../models/useMst'

interface ISidenavButtonProps {
  id?: string
  icon: JSX.Element | null
  name: string
  onClick?: () => void
  prominent?: boolean
  text: string
}

// Sidenav button component
export default function SidenavButton(props: ISidenavButtonProps): JSX.Element {
  const { id, icon, name, onClick, prominent = false, text } = props
  const { typography } = useTheme()

  const { router } = useMst()
  const { appendRoute } = router

  const onButtonClick = useCallback(() => {
    if (!id) {
      return
    }
    appendRoute({ name, state: { id, visible: true, prominent } })
    onClick?.()
  }, [appendRoute, id, name, onClick, prominent])

  return (
    <ListItemButton component={'button'} sx={{ width: '100%' }} onClick={onButtonClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Typography
          variant="subtitle2"
          sx={{
            ...typography.subtitle2.onSurface.light.highEmphasis,
          }}
        >
          {text}
        </Typography>
      </ListItemText>
    </ListItemButton>
  )
}
