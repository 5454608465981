import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import useMst from '../../models/useMst'
import PrimaryMessage from '../PrimaryMessage'

export default observer(function Messages() {
  const { messages } = useMst()
  const { onDismissMessage, messageQueue } = messages

  const queue = getSnapshot(messageQueue)
  const message = queue[0]
  const key = message?.id
  const messageText = message?.message || ''

  return (
    <PrimaryMessage
      key={key}
      message={messageText}
      type={message?.type || 'success'}
      visible={!!message}
      onClose={() => onDismissMessage()}
    />
  )
})
