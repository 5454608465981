import { useCallback, useState } from 'react';

export default function useDrawer() {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = useCallback(
		(setOpen: boolean) => () => {
			setDrawerOpen(setOpen);
		},
		[]
	);
	const openDrawer = toggleDrawer(true);
	const closeDrawer = toggleDrawer(false);

	return { drawerOpen, closeDrawer, openDrawer };
}
