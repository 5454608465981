type FontIconProps = {
  className?: string
  name: string
}

const FontIcon = ({ className = '', name }: FontIconProps) => {
  // currently only material icons are supported
  const iconSet = 'material-symbols-outlined'
  return <span className={`${iconSet} ${className}`}>{name}</span>
}

export default FontIcon
