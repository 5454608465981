import { ListItem } from '@mui/material'
import { useMemo } from 'react'
import SidenavButton from '../SidenavButton'

export interface ISidenavItem {
  key: string
  icon: JSX.Element | null
  title: string
  name: string
  onClick?: () => void
  prominent?: boolean
  id?: string
}

export interface ISidenavSection {
  key: string
  title?: string
  items: ISidenavItem[]
  ageGroup?: 'primary' | 'secondary'
}

interface ISectionItemsProps extends Omit<ISidenavSection, 'title' | 'key'> { }

const SidenavListSection = (props: ISectionItemsProps) => {
  const { items } = props

  const list = useMemo(
    () =>
      items.map((el) => {
        return (
          <ListItem key={el.key} disablePadding>
            <SidenavButton
              prominent={el.prominent}
              name={el.name}
              id={el.id}
              icon={el.icon}
              onClick={el.onClick}
              text={el.title}
            />
          </ListItem>
        )
      }),
    [items]
  )

  return <>{list}</>
}

export default SidenavListSection
