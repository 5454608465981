import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import useMst from '../../../models/useMst'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import OnboardingGroups from './OnboardingGroups'
import useGetOnboardingGroups from './useGetOnboardingGroups'

export default observer(function OnboardingPage(): JSX.Element {
  const { data: groups, error, isError, isLoading, isSuccess, isFetching } = useGetOnboardingGroups()

  // Grab the Mobx State Tree store
  const { onboarding } = useMst()

  // Gran the setShowOnboarding function from the onboarding store
  const { setShowOnboarding } = onboarding

  // If the groups have loaded successfully and the length is 0, set the show onboarding to false
  useEffect(() => {
    if (isSuccess && groups?.length === 0) {
      setShowOnboarding(false)
    }
  }, [isSuccess, groups, setShowOnboarding])

  // render the RequestLoadingHandler component with the onboarding groups as children
  return (
    <RequestLoadingHandler
      error={error}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      fetchStatus={'fetching'}
    >
      <OnboardingGroups groups={groups} />
    </RequestLoadingHandler>
  )
})
