import { Divider, List, Typography, useTheme } from '@mui/material'
import Box from '@mui/system/Box/Box'
import { FetchStatus } from '@tanstack/react-query'
import { useMemo } from 'react'
import { IDrawerProps } from '../Drawer'
import RequestLoadingHandler from '../RequestLoadingHandler'
import SidenavListSection, { ISidenavSection } from '../SidenavListSection'

interface ISidenavDrawerProps extends IDrawerProps {
  sections: ISidenavSection[]
  isError: boolean
  error?: unknown
  isLoading: boolean
  isSuccess: boolean
  fetchStatus: FetchStatus
}

export default function SidenavDrawer(props: ISidenavDrawerProps): JSX.Element {
  const { error, isError, isLoading, isSuccess, fetchStatus, sections } = props
  const { onClose } = props
  const { typography } = useTheme()

  const innerJSX = useMemo(
    () =>
      sections.map((el, idx) => {
        const itemsCopy = [...el.items]
        const itemsWithClose = itemsCopy.map((i) => {
          return {
            ...i,
            onClick: () => {
              i.onClick?.()
              onClose()
            },
          }
        })

        return (
          <Box key={el.key}>
            <Box sx={{ paddingTop: '16px' }}>
              <Typography
                className="mx-4"
                variant={'subtitle2'}
                sx={{ ...typography.subtitle2.onSurface.light.lowEmphasis }}
              >
                {el.title}
              </Typography>
              <List>
                <SidenavListSection items={itemsWithClose} />
              </List>
            </Box>
            {idx < sections.length - 1 ? <Divider /> : null}
          </Box>
        )
      }),
    [onClose, sections, typography.subtitle2.onSurface.light.lowEmphasis]
  )

  return (
    <>
      <RequestLoadingHandler
        error={error}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        fetchStatus={fetchStatus}
      >
        {innerJSX}
      </RequestLoadingHandler>
    </>
  )
}
