import { useUser } from '@auth0/nextjs-auth0/client'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect } from 'react'
import {
  findYourAccountRouteName,
  homeFeedRoute,
  homeFeedRouteName,
  loginRoute,
  loginRouteName,
  onboardingRouteName,
  publicRoutes,
  resourceLibraryRouteName,
} from '../../../constants'
import useInitialLoading from '../../../hooks/requests/useInitialLoading'
import useMst from '../../../models/useMst'
import { AnalyticsContextProvider } from '../../Analytics/AnalyticsContext'
import AppLoadingScreen from '../../AppLoadingScreen'
import FindYourAccountPage from '../../Auth/FindYourAccountPage'
import LoginPage from '../../Auth/LoginPage'
import { INavigator } from '../../Navigation/types'
import useNavigator from '../../Navigation/useNavigator'
import HomeFeedPage from '../HomeFeedPage'
import OnboardingPage from '../OnboardingPage'

import ResourceListPage from '../ResourceListPage'

const Screens: Record<string, React.ComponentType> = {
  [loginRouteName]: LoginPage,
  [homeFeedRouteName]: HomeFeedPage,
  [resourceLibraryRouteName]: ResourceListPage,
  [findYourAccountRouteName]: FindYourAccountPage,
}

const RouteNames = new Set(Object.keys(Screens))

/*
 * Renders root page of the app
 */
const RootPage: FC<{}> = observer(({}) => {
  const { onboarding, initialLoading, router } = useMst()

  const { isDone } = initialLoading

  const { root } = router

  const { user, isLoading: isLoadingUser } = useUser()

  const navigator: INavigator = useNavigator()

  useEffect(() => {
    if (!RouteNames.has(root.name)) {
      if (user) {
        navigator.replaceRoot(homeFeedRoute)
      } else {
        navigator.replaceRoot(loginRoute)
      }
    } else {
      const isPublic = publicRoutes.has(root.name)
      if (isPublic && user) {
        navigator.replaceRoot(homeFeedRoute)
      } else if (!isPublic && !user) {
        navigator.replaceRoot(loginRoute)
      }
    }
  }, [navigator, root.name, user])

  // Carry out the initial loading if the initial loading is not done and the root is not the login page
  useInitialLoading({ enabled: !isDone && root.name !== loginRouteName })

  // If the initial loading is not done and the root is not the login page, show the AppLoadingScreen
  if (isLoadingUser || (!isDone && root.name !== loginRouteName)) {
    return <AppLoadingScreen />
  } else if (onboarding?.showOnboarding && root.name !== loginRouteName) {
    return (
      <AnalyticsContextProvider section={onboardingRouteName}>
        <OnboardingPage />
      </AnalyticsContextProvider>
    )
  } else {
    const Page = Screens[root.name]
    if (Page) {
      return (
        <AnalyticsContextProvider section={root.name}>
          <Page />
        </AnalyticsContextProvider>
      )
    } else {
      // Error: unknown route. The useEffect above will redirect to the correct route
      return <></>
    }
  }
})

export default RootPage
