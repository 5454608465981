import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import OnboardingScreen, { IOnboardingScreenProps } from './OnboardingScreen'

// Import Swiper styles
import { useCallback, useEffect } from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import { useAnalytics } from '../../../Analytics/AnalyticsContext'

export interface IOnboardingSwiperProps {
  screens: IOnboardingScreenProps[]
}

export default function OnboardingSwiper(props: IOnboardingSwiperProps): JSX.Element {
  const { screens } = props

  const { setCurrentOnboardingCardTitle, trackOnboardingCardNext, trackOnboardingCardPrev } = useAnalytics()

  const firstCardTitle = screens[0]?.title ?? ''
  useEffect(() => {
    setCurrentOnboardingCardTitle(firstCardTitle)
  }, [firstCardTitle, setCurrentOnboardingCardTitle])

  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      const currentTitle = screens[swiper.realIndex]?.title ?? ''
      setCurrentOnboardingCardTitle(currentTitle)

      if (swiper.previousIndex < swiper.activeIndex) {
        trackOnboardingCardNext(currentTitle)
      }
      if (swiper.previousIndex > swiper.activeIndex) {
        trackOnboardingCardPrev(currentTitle)
      }
    },
    [screens, setCurrentOnboardingCardTitle, trackOnboardingCardNext, trackOnboardingCardPrev]
  )

  return (
    <Swiper
      className="onboarding-swiper h-full"
      slidesPerView={1}
      spaceBetween={30}
      centeredSlides={true}
      loop={false}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      onSlideChange={handleSlideChange}
    >
      {screens.map((screen, idx) => (
        <SwiperSlide className="onboarding-swiperScreen" key={`${idx}_screen.id`}>
          <OnboardingScreen
            imageAlt={screen.imageAlt}
            id={screen.id}
            image={screen.image}
            title={screen.title}
            text={screen.text}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
