import { Dialog, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { ScreenTransitionDuration, stackScreenBaseZIndex } from '../../constants'
import { TransitionStyle } from '../../models/Router'
import useMst from '../../models/useMst'
import StackScreen from '../StackScreen'

const SlideToLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} timeout={ScreenTransitionDuration} {...props} />
})

const SlideUp = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} timeout={ScreenTransitionDuration} {...props} />
})

/*
 * Responsible for rendering the stacked pages based on the MST router
 */

export default observer(function StackNavigation(): JSX.Element {
  const { persistentStorage, router } = useMst()
  const { shouldShowStackNavigation } = persistentStorage
  const { root } = router
  const routes = toJS(router.routes)

  const routesMap = useMemo(() => {
    if (shouldShowStackNavigation(root)) {
      return routes.map((r, idx) => {
        const Transition = r.transitionStyle === TransitionStyle.slideUp ? SlideUp : SlideToLeft
        return (
          <Dialog
            sx={{ zIndex: stackScreenBaseZIndex + idx }}
            key={r.key}
            fullScreen
            open={r.state.visible}
            TransitionComponent={Transition}
          >
            <StackScreen route={r} />
          </Dialog>
        )
      })
    }
  }, [shouldShowStackNavigation, root, routes])

  return <>{routesMap}</>
})
