import { EntrySys } from 'contentful'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { ICardFields } from '../../../@types/generated/contentful'
import { ContentTypes } from '../../../api/contentTypeIds'
import { ResolvedFields, SimpleEntry } from '../../../api/types'
import useGetAllEntries from '../../../hooks/requests/useGetAllEntries'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import HomeFeedCards from './Cards/HomeFeedCards'
import { extractCardData } from './constants'

export interface IButtonData {
  id: string
  title: string
  slug: string
}

interface Tag {
  sys: {
    id: string
    linkType: string
    type: string
  }
}

type CardFields = ResolvedFields<ICardFields>
export interface ICardData {
  id: string
  cardType: CardFields['cardType']
  details: CardFields['details']
  title: string
  bodyHtml: string
  dismissable: boolean
  button: CardFields['button'] | undefined
  buttonText: string | undefined
  priority: number | undefined
  roles: string[]
  sys: EntrySys
  metadata: {
    tags: Tag[]
  }
}

export default observer(function HomeFeedList(): JSX.Element {
  const { t } = useTranslation('home-feed')

  // Use the useGetAllEntries hook to get all the cards that  have the isVisible field set to true
  const { data, isLoading, isSuccess, isError, error, fetchStatus } = useGetAllEntries<ICardFields>(ContentTypes.card, {
    'fields.isVisible': true,
  })

  // Get a memoized value for the current date-time as an iso string
  const now = useMemo(() => new Date().toISOString(), [])

  // Get the cards content. Memoize it so it doesn't recompute on every render
  // If the data has an expiry date, filter out the cards that have expired
  const cardsContent: SimpleEntry<ICardFields>[] | undefined = useMemo(
    () => data?.filter((c) => !c.fields?.expiryDate || c.fields?.expiryDate > now),
    [data, now]
  )

  const cardData: ICardData[] | undefined = useMemo(() => {
    return extractCardData(cardsContent, t('takeAction'))
  }, [cardsContent, t])

  return (
    <div className="flex-1 h-full w-full flex flex-col p-[16px]">
      <RequestLoadingHandler
        error={error}
        fetchStatus={fetchStatus}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        loadingClassName="py-16"
      >
        <HomeFeedCards cardData={cardData} />
      </RequestLoadingHandler>
    </div>
  )
})
