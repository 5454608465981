import { useTranslation } from 'next-i18next'
import AppBarBurger from '../../AppBarBurger'
import AuthLayout from '../../AuthLayout'
import CacheableContent from '../../CacheableContent/CacheableContent'
import CamfedAppBar from '../../CamfedAppBar'
import HomeFeedList from './HomeFeedList'

/*
 * Renders the home feed page layout
 */
export default function HomeFeedPage(): JSX.Element {
  const { t } = useTranslation('home-feed')
  const title = t('title')

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBurger />} />}>
      <CacheableContent />

      <div className="flex-1 flex flex-col justify-center items-center">
        <HomeFeedList />
      </div>
    </AuthLayout>
  )
}
