import { useUser } from '@auth0/nextjs-auth0/client'
import { FC } from 'react'
import useMyProfile from '../Auth/useMyProfile'
import useTags from '../ResourceLibrary/FilterView/useTags'
import useResourceLibrary from '../ResourceLibrary/ResourceList/useResourceLibrary'
import useGetCurriculums from '../pages/CurriculumPage/hooks/useGetCurriculums'
import useGetMyBetterWorldGroups from '../pages/MyBetterWorldGroups/hooks/useGetMyBetterWorldGroups'
import useMyBetterWorldGroups from '../pages/MyBetterWorldGroups/hooks/useMyBetterWorldGroups'
import useGetMyGuides from '../pages/MyGuides/OverviewPage/hooks/useGetMyGuides'
import useGetSocialSupportCases from '../pages/SocialSupportCases/hooks/useGetSocialSupportCases'
import useGetSocialSupportCategories from '../pages/SocialSupportCases/hooks/useGetSocialSupportCategories'
import useSocialSupportCases from '../pages/SocialSupportCases/hooks/useSocialSupportCases'

/**
 * Actively fetches cacheable content from server. Effectively caching the content.
 */
const CacheableContent: FC<{}> = ({}) => {
  const { user } = useUser()

  useMyProfile()
  useResourceLibrary()
  useTags()
  useGetCurriculums()
  useGetSocialSupportCategories()
  useGetMyBetterWorldGroups()
  useGetSocialSupportCases(user?.sub ?? '', true)
  useGetMyGuides()
  useMyBetterWorldGroups()
  useSocialSupportCases()

  return null
}

export default CacheableContent
