import { observer } from 'mobx-react-lite'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { translationConfig } from '../components/Languages/constants'
import Messages from '../components/Messages'
import NoSSR from '../components/NoSSR'
import RootPage from '../components/pages/RootPage'

import StackNavigation from '../components/StackNavigation'

export default observer(function Home() {
  return (
    <>
      <Head>
        <title>CAMFED Guide Hub</title>
      </Head>
      <NoSSR>
        <Messages />
        <RootPage />
        <StackNavigation />
      </NoSSR>
    </>
  )
})

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(
        locale,
        [
          'account-profile',
          'account-settings',
          'auth',
          'common',
          'curriculum',
          'footer',
          'home-feed',
          'insights',
          'library',
          'loading-handler',
          'my-better-world-group-form',
          'my-better-world-groups',
          'my-guides',
          'network',
          'reports',
          'request-storage-permission',
          'resource-library',
          'social-support-case-action-form',
          'social-support-cases-form',
          'social-support-cases',
          'validation',
        ],
        translationConfig
      )),
    },
  }
}
