import Image from 'next/image'
import { INavigationSectionFields } from '../../@types/generated/contentful'
import { ContentTypes } from '../../api/contentTypeIds'
import { MaybeEntries } from '../../api/types'
import FontIcon from '../../components/Common/FontIcon'
import { ISidenavItem, ISidenavSection } from '../../components/SidenavListSection'

/**
 * Responsible for taking the navigation data to create
 * the side nav sections data
 */

interface IExtractSideNavSectionsProps {
  navigationData?: MaybeEntries<INavigationSectionFields>
  userRoleId: string
  country: string
}

export default function extractSideNavSections(props: IExtractSideNavSectionsProps): ISidenavSection[] {
  const { navigationData, userRoleId, country } = props
  const sidenavSections: ISidenavSection[] = []

  const matchesLangTag = (tags: any[]) =>
    tags.length === 0 ||
    tags.some((tag) => {
      const v = tag.sys.id
      return !v.includes('country') || v === `country${country}`
    })

  navigationData?.forEach((ns) => {
    if (!('fields' in ns)) return

    const sectionCountryTags = ns.metadata?.tags || []

    if (!matchesLangTag(sectionCountryTags)) return

    const section: ISidenavSection = {
      title: ns.fields?.title || '',
      key: ns.sys.id,
      items: [],
      // @ts-ignore
      ageGroup: ns.fields?.ageGroup || 'primary',
    }

    ns.fields?.links?.forEach((l) => {
      if (!('fields' in l) || !matchesLangTag(l.metadata?.tags || [])) return

      const ct = l.sys.contentType.sys.id
      let name: string =
        ct === ContentTypes.appPage && 'name' in l.fields! ? (l.fields!['name'] as string) : l.sys.contentType.sys.id

      const x: ISidenavItem = {
        key: l.sys.id,
        icon: l.fields?.icon && 'fields' in l.fields.icon ? (
          <Image width={24} height={24} src={encodeURI(`https:${l.fields.icon.fields?.file?.url}`)} alt="" />
        ) : l.fields?.iconName ? (
          <FontIcon name={l.fields.iconName} />
        ) : null,
        title: l.fields?.title || '',
        name,
        prominent: true,
        id: l.sys.id,
      }

      section.items.push(x)
    })

    sidenavSections.push(section)
  })

  return sidenavSections
}
