import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Screens } from '../../constants'
import { IRoute } from '../../models/Router'
import { AnalyticsContextProvider } from '../Analytics/AnalyticsContext'
import useNavigator from '../Navigation/useNavigator'

interface IModalLayoutProps {
  route: IRoute
}

/*
 * Responsible for rendering the stack screen based
 */
export default observer(function StackScreen(props: IModalLayoutProps): JSX.Element {
  const { route } = props
  const name = route.name
  const id = route.state.id

  const navigator = useNavigator()

  const Screen = Screens[name]

  if (Screen) {
    return (
      <AnalyticsContextProvider section={name}>
        <Screen navigator={navigator} id={id} data={route.state.data} />
      </AnalyticsContextProvider>
    )
  } else {
    return (
      <div className="text-center">
        <h1>Screen not found</h1>
        <div>
          <Button onClick={navigator.pop} variant="contained">
            Back
          </Button>
        </div>
      </div>
    )
  }
})
