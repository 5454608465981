import LanguageIcon from '@mui/icons-material/Language'
import { Alert, Button, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useCallback } from 'react'
import { loginPage } from '../../constants'
import useIsOnline from '../../hooks/offline/useIsOnline'
import useConstrainedImage from '../../hooks/useConstrainedImage'
import logo from '../../public/images/camfed_logo.png'
import useCurrentLanguage from '../Languages/useCurrentLanguage'
import useLanguageDialog from '../Languages/useLanguageDialog'
import ResponsiveFrame from './ResponsiveFrame'

const LoginPage: FC<{}> = observer(({}) => {
  const { typography } = useTheme()
  const { t } = useTranslation('auth')
  const { t: tCommon } = useTranslation('common')
  const router = useRouter()

  const { languageDialog, presentLanguageDialog } = useLanguageDialog()
  const language = useCurrentLanguage()

  const handleLoginClick = useCallback(() => {
    router.push(loginPage())
  }, [router])

  const handleLoginWithSmsClick = useCallback(() => {
    router.push(loginPage('sms'))
  }, [router])

  const handleLoginWithEmailClick = useCallback(() => {
    router.push(loginPage('email'))
  }, [router])

  const { setParentElement, width, height, onLoadingComplete } = useConstrainedImage({
    maxHeight: 80,
  })

  const isOnline = useIsOnline()

  return (
    <ResponsiveFrame>
      <div className="flex flex-col justify-center h-full" ref={setParentElement}>
        <Image
          alt=""
          className="max-w-full object-contain object-left"
          src={logo}
          width={width}
          height={height}
          onLoadingComplete={onLoadingComplete}
        />
        <Typography className="font-bold mt-6" variant="h4">
          {tCommon('appName')}
        </Typography>
        <div className="mt-7 flex flex-col">
          <Button
            className="mt-4"
            color="primary"
            disabled={!isOnline}
            onClick={handleLoginClick}
            sx={{ ...typography.button.primary.dark }}
            variant="contained"
          >
            {t('signIn')}
          </Button>
          <Button
            className="mt-4"
            color="primary"
            disabled={!isOnline}
            onClick={handleLoginWithSmsClick}
            sx={{ ...typography.button.primary.dark }}
            variant="contained"
          >
            {t('signInWithSms')}
          </Button>
          {/* <Button
            className="mt-4"
            color="primary"
            disabled={!isOnline}
            onClick={handleLoginWithEmailClick}
            sx={{ ...typography.button.primary.dark }}
            variant="contained"
          >
            {t('signInWithEmail')}
          </Button> */}
        </div>
        <div className="mt-4">
          <Button onClick={presentLanguageDialog}>
            <LanguageIcon className="mr-2" /> {language?.name}
          </Button>
        </div>
        {isOnline ? null : (
          <Alert className="mt-4" severity="warning">
            {tCommon('network:youAreOffline')}
          </Alert>
        )}
      </div>
      {languageDialog}
    </ResponsiveFrame>
  )
})

export default LoginPage
