import { Button, Dialog, Slide, Typography, useTheme } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { onboardingDialogZIndex } from '../../../../constants'
import { IGroupProps } from '../OnboardingGroups'
import OnboardingSwiper from '../OnboardingSwiper'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export interface IOnboardingGroupProps {
  group: IGroupProps
  onClick: (groupId: string) => void
  open: boolean
}

export default function OnboardingGroup(props: IOnboardingGroupProps): JSX.Element {
  const { group, open, onClick } = props

  //Get the typography from the theme
  const { typography } = useTheme()

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      key={group.id}
      sx={{ zIndex: onboardingDialogZIndex }}
    >
      <div className="relative h-full flex flex-col">
        <Typography className="my-6 text-center px-4 w-full" variant="h4" sx={typography.h5_onSurface.light}>
          {group.groupTitle}
        </Typography>
        <div className="flex-1 flex flex-col">
          <div className="flex-1 relative">
            {group.screens.length > 0 && <OnboardingSwiper screens={group.screens} />}
          </div>
        </div>
        <Button
          sx={{ position: 'absolute', zIndex: 100, bottom: 30, left: '50%', transform: 'translateX(-50%)' }}
          variant="outlined"
          onClick={() => onClick(group.id)}
        >
          {group.buttonText}
        </Button>
      </div>
    </Dialog>
  )
}
