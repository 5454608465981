import { toJS } from 'mobx'
import { useMemo } from 'react'
import useMst from '../../../../models/useMst'
import { ICardData } from '../HomeFeedList'

interface IUseHideCardDataProps {
  cardData: ICardData[] | undefined
}

// Responsible for hiding cards that have been dismissed by the user

export default function useHideCardData({ cardData }: IUseHideCardDataProps) {
  const { homeFeed } = useMst()
  const { hiddenIds } = homeFeed
  const hids = toJS(hiddenIds)

  return useMemo(() => cardData?.filter((c) => !hids.includes(c.id)), [cardData, hids])
}
