import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { ICardFields, IRoleFields } from '../../../@types/generated/contentful'
import { SimpleEntry } from '../../../api/types'
import { ICardData } from './HomeFeedList'

export const extractCardData = (entries: SimpleEntry<ICardFields>[] | undefined, textFallback: string) =>
  entries?.map((c) => {
    // Get the roles from the linked roles fields
    const linkedRoles = c.fields?.role
      ?.filter((v): v is SimpleEntry<IRoleFields> => v.hasOwnProperty('fields'))
      .map((r) => r.fields?.role)

    const card: ICardData = {
      id: c.sys.id,
      cardType: c.fields!.cardType,
      details: c.fields!.details,
      title: c.fields!.title || textFallback,
      bodyHtml: documentToHtmlString(c.fields!.body),
      dismissable: c.fields!.dismiss,
      button: c.fields!.button,
      buttonText: c.fields!.buttonText,
      priority: c.fields!.priority,
      roles: linkedRoles as string[],
      metadata: c.metadata,
      sys: c.sys,
    }
    return card
  })

export const sortCardsByCreatedDate = (cardData: ICardData[] | undefined) =>
  cardData?.sort((a, b) => {
    const t1 = a.sys.createdAt
    const t2 = b.sys.createdAt

    const p1 = a.priority ?? undefined // normalize null to undefined
    const p2 = b.priority ?? undefined // normalize null to undefined

    if (p1 !== undefined && p2 !== undefined) {
      // if both cards have a priority, sort by priority, smaller first
      if (p1 < p2) {
        return -1
      } else if (p1 > p2) {
        return 1
      }
    } else if (p1 !== undefined && p2 === undefined) {
      // if a has a priority, sort it first
      return -1
    } else if (p1 === undefined && p2 !== undefined) {
      // if b has a priority, sort it first
      return 1
    }

    // if both cards have the same priority, sort by created date, older first
    if (t1 > t2) {
      return 1
    } else if (t1 < t2) {
      return -1
    } else {
      return 0
    }
  })
