import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import useMst from '../../../../models/useMst'
import { useAnalytics } from '../../../Analytics/AnalyticsContext'
import OnboardingGroup from '../OnboardingGroup'
import { IOnboardingSwiperProps } from '../OnboardingSwiper'

export interface IGroupProps extends IOnboardingSwiperProps {
  buttonText: string
  id: string
  groupTitle: string
  role: string
}

export interface IOnboardingGroupsProps {
  groups: IGroupProps[]
}

export default observer(function OnboardingGroups(props: IOnboardingGroupsProps): JSX.Element {
  const { groups } = props

  const { onboarding } = useMst()
  const { addCompletedId, completedOnboardingIds, setShowOnboarding } = onboarding
  // Convert the observable into a plain JS array to use in the useMemo so always updates when the array changes
  const completedIds = toJS(completedOnboardingIds)

  const { trackOnboardingCardDismiss } = useAnalytics()

  // Onclick handler for the button
  // On click, add the ID of the group to the completedOnboardingIds array and update the showOnboarding state
  const onClick = useCallback(
    (groupId: string) => {
      const newIds = [...completedOnboardingIds, groupId]
      addCompletedId(groupId)

      trackOnboardingCardDismiss()

      // Get whether any of the groups have not been completed
      const hasUncompleted = groups.some((group) => !newIds.includes(group.id))
      setShowOnboarding(hasUncompleted)
    },
    [addCompletedId, completedOnboardingIds, groups, setShowOnboarding, trackOnboardingCardDismiss]
  )

  const groupsToRender = useMemo(() => {
    // Only show the groups if they have not been completed and no other groups are open
    let alreadyShowing = false

    return groups.map((group) => {
      const open = !completedIds.includes(group.id) && !alreadyShowing
      if (open) {
        alreadyShowing = true
      }
      return <OnboardingGroup key={group.id} group={group} onClick={onClick} open={open} />
    })
  }, [completedIds, groups, onClick])

  return <>{groupsToRender}</>
})
