import { observer } from 'mobx-react-lite'
import { RenderTracker } from '../../../../RenderTrackerContext/RenderTrackerContext'
import useResolvedProfile from '../../../Account/Profile/hooks/useResolvedProfile'

interface ProfileCardProps {
  children: React.ReactNode
}

const ProfileCard = observer(({ children }: ProfileCardProps): JSX.Element => {
  const { data: profile, isLoading, isSuccess } = useResolvedProfile()

  if (isLoading) {
    return <RenderTracker isLoading />
  } else if (isSuccess && profile) {
    if (profile.contact_email && profile.contact_phone_number) {
      // profile is complete
    } else {
      return <>{children}</>
    }
  }
  return <></>
})

export default ProfileCard
