/**
 * Renders an Image, a typography of h5, a typography subtitle and a button
 *
 * The component takes up the full height and width of the parent container and has a white background
 *
 * The  image, typography and button are centered in the parent container
 */

import { Box, Typography, useTheme } from '@mui/material'

export interface IOnboardingScreenProps {
  id: string
  image: string
  imageAlt: string
  title: string
  text?: string
}

export default function OnboardingScreen(props: IOnboardingScreenProps): JSX.Element {
  const { image, imageAlt, title, text } = props

  const { typography } = useTheme()

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <div className="flex-1 relative w-full">
        <div
          className="absolute left-16 right-16 h-full bg-no-repeat bg-center bg-contain"
          style={{ backgroundImage: `url(${image})` }}
        />
      </div>
      <div className="px-8 pb-24">
        <Typography variant="h5" sx={{ textAlign: 'center', marginTop: '20px', ...typography.h5_onSurface.light }}>
          {title}
        </Typography>
        <Typography
          className="text-center mt-5"
          variant="subtitle1"
          sx={typography.subtitle2.onSurface.light.lowEmphasis}
        >
          {text}
        </Typography>
      </div>
    </Box>
  )
}
