import { useUser } from '@auth0/nextjs-auth0/client'
import { useMemo } from 'react'
import { INavigationFields } from '../../@types/generated/contentful'
import { ContentTypes } from '../../api/contentTypeIds'
import useSearchEntries from '../../hooks/requests/useSearchEntries'
import extractSideNavSections from '../../utils/requests/extractSideNavSections'

interface IUseSideSectionsProps {
  roleId: string
}

/**
 * Responsible for setting up the side nav sections for a given navigation content ID
 */

export default function useSideNavSections(props: IUseSideSectionsProps) {
  const { roleId } = props
  const u = useUser()
  const country = u.user?.meta?.country

  // Get the navigation content model data from Contentful
  const {
    data: navigationData,
    isSuccess,
    isError,
    error,
    isLoading,
    fetchStatus,
  } = useSearchEntries<INavigationFields>(ContentTypes.navigation, {
    'fields.role.sys.id': roleId,
    include: 3,
  })

  // Use the fetched data to generate the side nav sections
  const navSectionData = navigationData?.items?.[0]?.fields?.navigationSection
  const sideNavSections = useMemo(
    () => extractSideNavSections({ navigationData: navSectionData, userRoleId: roleId, country }),
    [navSectionData, roleId, country]
  )

  return { sideNavSections: sideNavSections, isSuccess, isError, error, isLoading, fetchStatus }
}
