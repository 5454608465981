import { observer } from 'mobx-react-lite'
import { PropsWithChildren } from 'react'
import { RenderTracker } from '../../../../RenderTrackerContext/RenderTrackerContext'
import useGetMyBetterWorldGroups from '../../../MyBetterWorldGroups/hooks/useGetMyBetterWorldGroups'

interface MilestoneCardProps extends PropsWithChildren {
  curriculumId: string
  stepsCompleted: number
  year: string
}

const MilestoneCard = observer(({ children, curriculumId, stepsCompleted, year }: MilestoneCardProps) => {
  const { data: groups, isLoading } = useGetMyBetterWorldGroups()
  const hasReached = groups?.some((group) => {
    if (group.completed || group.isDeleted) return false // ignore completed or deleted groups
    if (group.curriculum_id !== curriculumId) return false // ignore groups with different curriculum
    if (group.year !== year) return false // ignore groups with different year
    const count = group.steps?.length || 0
    return count >= stepsCompleted
  })

  if (isLoading) {
    return <RenderTracker isLoading />
  }

  return hasReached ? <>{children}</> : <></>
})

export default MilestoneCard
