import { useMemo } from 'react'
import { ICardData } from '../HomeFeedList'

export interface IUseCardDataRolesProps {
  cardData: ICardData[] | undefined
  userRoles: IUserRole[] // Roles of the user
}

export default function useCardDataRoles({ cardData, userRoles }: IUseCardDataRolesProps) {
  return useMemo(() => {
    return cardData?.filter((c) => {
      // The roles that can view the card data
      const cardRoles = new Set(c.roles)

      return userRoles.some((r) => cardRoles.has(r.name))
    })
  }, [cardData, userRoles])
}
