import MenuIcon from '@mui/icons-material/Menu'
import { IconButton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import useDrawer from '../Drawer/useDrawer'
import AppDrawer from '../Navigation/AppDrawer'

export default function AppBarBurger(): JSX.Element {
  const { drawerOpen, closeDrawer, openDrawer } = useDrawer()
  const { t } = useTranslation('common')

  return (
    <>
      <IconButton color="inherit" aria-label={t('menu') ?? ''} onClick={openDrawer} sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton>
      <AppDrawer isOpen={drawerOpen} onClose={closeDrawer} onOpen={openDrawer} />
    </>
  )
}
