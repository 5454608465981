import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

interface IRenderTrackerContextValue {
  childCount: number
  loadingChildCount: number
  didMount: (isLoading: boolean) => void
  didUnmount: (isLoading: boolean) => void
}

const DefaultRenderTrackerContextValue: IRenderTrackerContextValue = {
  childCount: 0,
  loadingChildCount: 0,
  didMount: () => {},
  didUnmount: () => {},
}

const RenderTrackerContext = createContext(DefaultRenderTrackerContextValue)

export const RenderTrackerContextProvider = ({ children }: { children: ReactNode }): ReactElement | null => {
  const [childCount, setChildCount] = useState(0) // number of rendered children
  const [loadingChildCount, setLoadingChildCount] = useState(0) // number of rendered children that are loading

  const didMount = useCallback((isLoading: boolean) => {
    if (isLoading) {
      setLoadingChildCount((prev) => prev + 1)
    }
    setChildCount((prev) => prev + 1)
  }, [])

  const didUnmount = useCallback((isLoading: boolean) => {
    if (isLoading) {
      setLoadingChildCount((prev) => prev - 1)
    }
    setChildCount((prev) => prev - 1)
  }, [])

  const value = useMemo(
    () => ({ childCount, didMount, didUnmount, loadingChildCount }),
    [childCount, didMount, didUnmount, loadingChildCount]
  )

  return <RenderTrackerContext.Provider value={value}>{children}</RenderTrackerContext.Provider>
}

export const useRenderTrackerContext = (): IRenderTrackerContextValue => {
  return useContext(RenderTrackerContext)
}

export const RenderTracker = (props: { isLoading?: boolean }): ReactElement | null => {
  const { isLoading } = props
  const { didMount, didUnmount } = useRenderTrackerContext()

  useEffect(() => {
    const loading = Boolean(isLoading)
    didMount(loading)
    return () => {
      didUnmount(loading)
    }
  }, [didMount, didUnmount, isLoading])

  return null
}
