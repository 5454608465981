import { ICardFields } from '../../@types/generated/contentful'
import { ContentTypes } from '../../api/contentTypeIds'
import useMst from '../../models/useMst'
import useGetAllEntries from './useGetAllEntries'
import useGetOnboarding from './useGetOnboarding'

// Props interface that accepts enabler
interface IUseInitialLoadingProps {
  enabled?: boolean
}

export default function useInitialLoading(props: IUseInitialLoadingProps) {
  // Destrcuture props
  const { enabled = true } = props

  // Get mobx store
  const { initialLoading } = useMst()

  // Get the onboarding state
  const { homeFeedCardsDone, onboardingDone, setHomeFeedCardsDone, setOnboardingDone } = initialLoading
  // Get the onboarding data and set enabled to true if onboarding is not done and enabled is true
  const { isSuccess: onboardingIsSuccess } = useGetOnboarding({ enabled: !onboardingDone && enabled })

  // use the useGetAllEntries hook to get all the cards that have isVisible set to true
  const { isSuccess: cardsIsSuccess } = useGetAllEntries<ICardFields>(
    ContentTypes.card,
    {
      'fields.isVisible': true,
    },
    !homeFeedCardsDone && enabled
  )

  // If onboarding is done, set onboardingDone to true
  if (onboardingIsSuccess) {
    setOnboardingDone(true)
  }

  // If cards are done, set homeFeedCardsDone to true
  if (cardsIsSuccess) {
    setHomeFeedCardsDone(true)
  }
}
