import { Box, SwipeableDrawer, useTheme } from '@mui/material'
import Image from 'next/image'
import { PropsWithChildren } from 'react'
import logo from '../../public/images/camfed_logo.png'

export interface IDrawerProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export default function Drawer(props: PropsWithChildren<IDrawerProps>): JSX.Element {
  const { children, isOpen, onClose, onOpen } = props
  const { palette, spacing } = useTheme()

  const headerHeight = 72
  const topPadding = spacing(1)
  const paddingNum = Number(topPadding.slice(0, spacing(1).length - 2))
  const imgHeight = headerHeight - 2 * paddingNum

  return (
    <SwipeableDrawer anchor="left" onClose={onClose} onOpen={onOpen} open={isOpen}>
      <Box
        sx={{
          width: '300px',
          backgroundColor: palette.onSurface.light_12,
        }}
      >
        <Box sx={{ padding: `${topPadding} 16px`, height: headerHeight }}>
          <Image alt="" src={logo} height={imgHeight} fill={false} />
        </Box>
      </Box>
      <Box sx={{ maxWidth: '300px' }}>{children}</Box>
    </SwipeableDrawer>
  )
}
