import { useUser } from '@auth0/nextjs-auth0/client'
import { Box } from '@mui/material'
import parse from 'html-react-parser'
import { useCallback } from 'react'
import {
  IAppPageFields,
  ICardFields,
  ICurriculumFields,
  IMilestoneCardFields,
  IPageFields,
  IResourceFields,
} from '../../../../@types/generated/contentful'
import { ContentTypes } from '../../../../api/contentTypeIds'
import { MaybeEntry, ResolvedFields, SimpleEntry } from '../../../../api/types'
import { curriculumRoute, pageRoute } from '../../../../constants'
import getLinkedResourceRoute from '../../../../utils/getLinkedResourceRoute'
import { useAnalytics } from '../../../Analytics/AnalyticsContext'
import ArticleCard from '../../../ArticleCard'
import { INavigator } from '../../../Navigation/types'
import { RenderTracker, useRenderTrackerContext } from '../../../RenderTrackerContext/RenderTrackerContext'
import SimpleActionButton from '../../../SimpleActionButton'
import SimpleCardBody from '../../../SimpleCardBody'
import SimpleCardHeader from '../../../SimpleCardHeader'
import { ICardData } from '../HomeFeedList'
import MilestoneCard from './MilestoneCard/MilestoneCard'
import ProfileCard from './ProfileCard/ProfileCard'

const PageButton = ({
  buttonText,
  navigator,
  onClick,
  page,
}: {
  buttonText: string | undefined
  navigator: INavigator
  onClick?: () => void
  page: SimpleEntry<IPageFields>
}) => {
  const label = buttonText || page.fields?.title
  const redirect = useCallback(() => {
    onClick?.()
    navigator.push(pageRoute(page.sys.id))
  }, [navigator, onClick, page.sys.id])
  return <SimpleActionButton onClick={redirect}>{label}</SimpleActionButton>
}

const ResourceButton = ({
  buttonText,
  navigator,
  onClick,
  resource,
}: {
  buttonText: string | undefined
  navigator: INavigator
  onClick?: () => void
  resource: SimpleEntry<IResourceFields>
}) => {
  const handleClick = useCallback(() => {
    onClick?.()
    const route = getLinkedResourceRoute({ id: resource.sys.id, type: ContentTypes.resource })
    if (route) {
      navigator.push(route)
    }
  }, [navigator, onClick, resource.sys.id])

  return <SimpleActionButton onClick={handleClick}>{buttonText || resource.fields?.title}</SimpleActionButton>
}

const AppPageButton = ({
  appPage,
  buttonText,
  onClick,
  navigator,
}: {
  appPage: SimpleEntry<IAppPageFields>
  buttonText: string | undefined
  onClick?: () => void
  navigator: INavigator
}) => {
  const handleClick = useCallback(() => {
    onClick?.()
    const name = appPage.fields?.name
    if (name) {
      navigator.push({
        name,
        state: { id: name, visible: true, prominent: false },
      })
    }
  }, [appPage.fields?.name, navigator, onClick])

  return <SimpleActionButton onClick={handleClick}>{buttonText || appPage.fields?.title}</SimpleActionButton>
}

const CurriculumButton = ({
  buttonText,
  curriculum,
  onClick,
  navigator,
}: {
  buttonText: string | undefined
  curriculum: SimpleEntry<ICurriculumFields>
  onClick?: () => void
  navigator: INavigator
}) => {
  const handleClick = useCallback(() => {
    onClick?.()
    const id = curriculum.sys.id
    if (id) {
      navigator.push(curriculumRoute(id))
    }
  }, [curriculum.sys.id, navigator, onClick])

  return <SimpleActionButton onClick={handleClick}>{buttonText || curriculum.fields?.title}</SimpleActionButton>
}

const ActionButton = ({
  button,
  buttonText,
  navigator,
  onClick,
}: {
  button: ResolvedFields<ICardFields>['button'] | undefined
  buttonText: string | undefined
  navigator: INavigator
  onClick?: () => void
}) => {
  if (button && button.sys && 'contentType' in button.sys) {
    if (button.sys.contentType.sys.id === 'page') {
      const page = button as SimpleEntry<IPageFields>
      return <PageButton buttonText={buttonText} navigator={navigator} onClick={onClick} page={page} />
    } else if (button.sys.contentType.sys.id === 'resource') {
      const resource = button as SimpleEntry<IResourceFields>
      return <ResourceButton buttonText={buttonText} navigator={navigator} onClick={onClick} resource={resource} />
    } else if (button.sys.contentType.sys.id === 'appPage') {
      const appPage = button as SimpleEntry<IAppPageFields>
      return <AppPageButton buttonText={buttonText} navigator={navigator} onClick={onClick} appPage={appPage} />
    } else if (button.sys.contentType.sys.id === 'curriculum') {
      const curriculum = button as SimpleEntry<ICurriculumFields>
      return (
        <CurriculumButton buttonText={buttonText} navigator={navigator} onClick={onClick} curriculum={curriculum} />
      )
    }
  }
  return null
}

const CardContent = ({
  card,
  navigator,
  onDismiss
}: {
  card: ICardData
  navigator: INavigator
  onDismiss: (id: string) => void
}) => {

  const { loadingChildCount } = useRenderTrackerContext()
  const { trackHomeCardAction, trackHomeCardDismiss } = useAnalytics()

  const handleActionClick = useCallback(() => {
    trackHomeCardAction(card.title)
  }, [card.title, trackHomeCardAction])

  const handleDismissClick = useCallback(() => {
    trackHomeCardDismiss(card.title)
    onDismiss(card.id)
  }, [card.id, card.title, onDismiss, trackHomeCardDismiss])

  if (loadingChildCount > 0) {
    return <RenderTracker />
  }

  return (
    <Box sx={{ marginBottom: '16px', width: '100%' }}>
      <RenderTracker /> {/* This is used to track when the card is rendered */}
      <ArticleCard
        actions={
          <>
            <ActionButton
              button={card.button}
              buttonText={card.buttonText}
              navigator={navigator}
              onClick={handleActionClick}
            />
            {card.dismissable ? <SimpleActionButton onClick={handleDismissClick}>Dismiss</SimpleActionButton> : null}
          </>
        }
      >
        <SimpleCardHeader text={card.title} />
        <SimpleCardBody>{parse(card.bodyHtml)}</SimpleCardBody>
      </ArticleCard>
    </Box>
  )
}

const Cards = ({
  cards,
  navigator,
  onDismiss
}: {
  cards: ICardData[] | undefined
  navigator: INavigator
  onDismiss: (key: string) => void
}) => {
  const u = useUser()
  const country = u.user?.meta?.country

  // Filter cards based on user's country
  const filteredCards = (cards ?? []).filter((card) => {
    const resTags = card.metadata?.tags ?? []

    const matchesLangTag =
      resTags.length === 0
        ? true
        : resTags.some((t) => {
          const v = t.sys.id
          // If there are no country tags, return true
          if (!v.includes('country')) {
            return true
          } else {
            // If there is a country tag, return true if it matches the user's country
            return v === `country${country}`
          }
        })

    return matchesLangTag
  })

  return (
    <>
      {(filteredCards ?? []).map((f) => {
        if (f.cardType === 'Profile') {
          return (
            <ProfileCard key={f.id}>
              <CardContent card={f} navigator={navigator} onDismiss={onDismiss} />
            </ProfileCard>
          )
        } else if (f.cardType === 'Milestone') {
          const details: MaybeEntry<IMilestoneCardFields> | undefined = f.details
          const curriculumId = details && 'fields' in details ? details?.fields?.curriculum?.sys.id : undefined
          const stepsCompleted = details && 'fields' in details ? details?.fields?.stepsCompleted : undefined
          const year = details && 'fields' in details ? details?.fields?.year : undefined

          if (curriculumId && typeof stepsCompleted === 'number' && typeof year === 'string') {
            return (
              <MilestoneCard key={f.id} curriculumId={curriculumId} stepsCompleted={stepsCompleted} year={year}>
                <CardContent card={f} navigator={navigator} onDismiss={onDismiss} />
              </MilestoneCard>
            )
          } else {
            // invalid card, don't render it
          }
        } else {
          // general card
          return <CardContent key={f.id} card={f} navigator={navigator} onDismiss={onDismiss} />
        }
      })}
    </>
  )
}

export default Cards
