import { Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'next-i18next'

export default function AppLoadingScreen(): JSX.Element {
  // Get the translation function
  const { t } = useTranslation('common')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
      }}
    >
      <div className="flex-1 flex flex-col justify-center items-center m-4">
        {/* Ignore eslint on next line  */}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img className="object-contain max-w-full" src={'/images/camfed_people.png'} alt={t('loading') ?? ''} />
        <CircularProgress className="mt-8" />
      </div>
    </Box>
  )
}
