import { useMemo, useState } from 'react'
import useRoles from '../../hooks/useRoles'
import ControlledSelect from '../ControlledSelect'
import Drawer, { IDrawerProps } from '../Drawer'
import SidenavDrawer from '../SidenavDrawer'
import useSideNavSections from '../SidenavDrawer/useSideNavSections'

interface ICoreTrainerDrawerProps extends IDrawerProps {}

export default function CoreTrainerDrawer(props: ICoreTrainerDrawerProps): JSX.Element | null {
  const { isOpen, onClose, onOpen } = props

  const roles = useRoles()

  const [selectedRole, setSelectedRole] = useState<string>(roles[0]?.id)

  console.log("ROLES ASSIGNED:", roles)

  // Create options for the role select
  const roleOptions = useMemo(() => {
    return roles.map((r) => ({ value: r.id, label: r.name }))
  }, [roles])

  const { fetchStatus, error, isError, isLoading, isSuccess, sideNavSections } = useSideNavSections({
    roleId: selectedRole,
  })

  return (
    <Drawer isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      {roles.length > 1 && (
        <div className="p-3">
          <ControlledSelect<string>
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value as string)}
            options={roleOptions}
            label={'Choose your role below:'}
          />
        </div>
      )}
      <SidenavDrawer
        fetchStatus={fetchStatus}
        error={error}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        sections={sideNavSections}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      />
    </Drawer>
  )
}
