import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import useRoles from '../../../../hooks/useRoles'
import useMst from '../../../../models/useMst'

import useNavigator from '../../../Navigation/useNavigator'
import PlaceholderMessage from '../../../PlaceholderMessage'
import {
  RenderTrackerContextProvider,
  useRenderTrackerContext,
} from '../../../RenderTrackerContext/RenderTrackerContext'
import { sortCardsByCreatedDate } from '../constants'
import { ICardData } from '../HomeFeedList'
import useCardDataRoles from '../hooks/useCardDataRoles'
import useHideCardData from '../hooks/useHideCardData'
import Cards from './Cards'

export interface IUseHomeFeedCardsProps {
  cardData: ICardData[] | undefined
}

interface IUseDismissableListProps {
  items?: JSX.Element
  emptyView: JSX.Element
}

const EmptySwitchView = ({ items, emptyView }: IUseDismissableListProps): JSX.Element => {
  const { childCount, loadingChildCount } = useRenderTrackerContext()

  return (
    <>
      {loadingChildCount > 0 ? (
        <div className="py-16 flex justify-center">
          <CircularProgress />
        </div>
      ) : null}
      {childCount === 0 ? emptyView : null}
      {items} {/* items must always be rendered to keep track of the count */}
    </>
  )
}

export default observer(function HomeFeedCards({ cardData }: IUseHomeFeedCardsProps) {
  const { t } = useTranslation('home-feed')

  const filteredCardData = useHideCardData({ cardData })

  const userRoles = useRoles()

  const roleBasedCardData = useCardDataRoles({ cardData: filteredCardData, userRoles })

  const orderedRBCardData = useMemo(() => sortCardsByCreatedDate(roleBasedCardData), [roleBasedCardData])

  const { homeFeed } = useMst()
  const { addHiddenId } = homeFeed
  const navigator = useNavigator()

  const cards = useMemo(
    () => <Cards cards={orderedRBCardData} navigator={navigator} onDismiss={addHiddenId} />,
    [addHiddenId, navigator, orderedRBCardData]
  )

  const emptyView = useMemo(
    () => (
      <div className="h-full flex-1 flex flex-col justify-center items-center">
        <PlaceholderMessage
          bodyText={t('exploreViaNavigationBar') ?? ''}
          header={t('youAreUpToDate!')}
          image={
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={'images/camfed_people.png'}
              alt={t('allCardsDismissed') ?? ''}
              className="max-h-full max-w-full object-contain"
            />
          }
        />
      </div>
    ),
    [t]
  )

  return (
    <RenderTrackerContextProvider>
      <EmptySwitchView items={cards} emptyView={emptyView} />
    </RenderTrackerContextProvider>
  )
})
